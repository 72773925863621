<template>
  <v-container fluid>
    <v-row>
      <v-subheader>Minha Empresa > Documentos Contábeis </v-subheader>
      <v-card width="100%" class="k-body">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>
        <div class="mx-6 k-clist">
          <v-row>
            <v-col cols="12" class="k-nav">
              <a class="k-nav-root" @click="navRoot">
                .../
              </a>
              <a
                class="k-nav-root"
                v-for="dir in path"
                :key="`dir-${dir}`"
                @click="navPath(dir)"
              >
                {{ dir }} /
              </a>
            </v-col>
          </v-row>
          <v-progress-linear
            class="k-loading-line"
            :indeterminate="loading"
            height="2"
            color="green darken-3"
          ></v-progress-linear>
          <v-row class="k-list" v-show="current">
            <v-col cols="12" class="k-folder" v-show="current.folders">
              <ul>
                <li
                  v-for="f in current.folders"
                  :key="f.id"
                  @click="navTo(f.path)"
                >
                  <v-icon color="green darken-3">folder</v-icon>
                  {{ f.info.folderName }}
                </li>
              </ul>
            </v-col>

            <v-col cols="12" v-show="current.files[0]">
              <v-data-table
                class="k-file"
                no-results-text="Não foi encontrado nada"
                no-data-text="Esta pasta não contém arquivos"
                page-text="ldlfk"
                :headers="headers"
                :items="current.files"
                :footer-props="{
                  itemsPerPageOptions: tableRowsPerPage,
                  itemsPerPageText: rowsPerPageText,
                }"
                :disable-sort="$vuetify.breakpoint.smAndDown"
              >
                <template v-slot:item.name="{ item }">
                  <v-icon color="gray darken-3">file_copy</v-icon>
                  {{ item.name }}
                </template>
                <template v-slot:item.dateReference="{ item }">
                  {{ item.dateReference | moment('DD/MM/YYYY') }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <k-download :id="item.id"></k-download>
                </template>
                <v-text-field
                  label="Items por pagina"
                  type="number"
                  min="-1"
                  max="15"
                ></v-text-field>
                <template slot="pageText" slot-scope="props">
                  {{ props.pageStart }} - {{ props.pageStop }} de
                  {{ props.itemsLength }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import kDownload from '@src/components/widget/Download.vue'
import axios from '@plugins/axios'
import camelCase from 'lodash/camelCase'

export default {
  name: 'TaxDocument',
  data() {
    return {
      loading: false,
      path: [],
      current: { files: [] },
      root: null,
      headers: [
        {
          text: 'Nome do arquivo',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Data Modificação',
          align: 'left',
          sortable: true,
          value: 'dateReference',
        },
        {
          text: '',
          align: 'right',
          value: 'actions',
          sortable: false,
        },
      ],
      headerTitle: 'Documentos Contábeis',
      headerDescription: `Você não precisa mais perder tempo procurando informações e documentos
      da sua empresa. Aqui você poderá acessar todos os seus dados, incluindo
      os relatórios contábeis e suas declarações fiscais.`,
    }
  },
  components: {
    kDownload,
  },
  computed: {
    ...mapState({
      tableRowsPerPage: (state) => state.layout.tableRowsPerPage,
      rowsPerPageText: (state) => state.layout.rowsPerPageText,
    }),
  },
  methods: {
    navRoot() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.current = this.root
        this.path = this.root.path
      }, this.randomTime())
    },
    navTo(path) {
      this.loading = true
      this.path = path
      let newFolder = {}
      let current = this.root
      this.path.forEach((p) => {
        let key = camelCase(p)
        current = current.folders[key]
      })
      setTimeout(() => {
        this.loading = false
        this.current = current
      }, this.randomTime())
    },
    navPath(path) {
      this.loading = true
      var newPath = []

      this.path.find((v) => {
        newPath.push(v)
        if (v === path) return true
      })

      this.navTo(newPath)
    },
    randomTime() {
      let min = 500
      let max = 800
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
  },
  mounted() {
    this.loading = true
    axios.get('tax-document').then((res) => {
      this.root = res.data
      this.current = res.data
      this.loading = false
    })
  },
}
</script>
<style lang="sass" scoped>

.k-clist
  margin-top: 0
  padding-top: 0 !important

.k-nav
  font-size: 20px
  margin-left: 25px

  a
    text-decoration: none
    cursor: pointer

.k-list
  ul
    list-style-type: none
    padding-left: 0px

    li
      min-height: 40px
      padding: 10px 10px 10px 20px
      border-bottom: 1px gray solid
      cursor: pointer

      i
        margin-right: 10px

  .k-folder
    .k-loading-line
      margin: 0
    li
      &:hover
        background: #e8f5e9
</style>
